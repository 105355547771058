
import { Injectable } from '@angular/core';
import {
    Action,
    Selector,
    State,
    StateContext,
    StateOperator,
} from '@ngxs/store';
import {
    EMPTY,
    Observable,
} from 'rxjs';
import {
    switchMap,
    tap,
} from 'rxjs/operators';

import { ContactsService } from '~/app/core/services/api/contacts/contacts.service';
import {
    ChangeContactErrorAction,
    ChangeContactFieldAction,
    ChangeContactFieldsAction,
    ChangeContactStateAction,
    CreateContactAction,
    CreateContactSuccessAction,
    GetContactAction,
    ResetContactAction,
    UpdateContactAction,
    UpdateFormIsValidAction,
} from '~/app/core/state/contacts-details/contacts-details.actions';
import { AddContactsToGroupsAction } from '~/app/core/state/contacts-list/contacts-list.actions';
import { DetailState } from '~/app/domains/contacts/enums/detail-state.enum';
import { Contact } from '~/app/domains/contacts/types/contact.type';
import { ErrorType } from '~/app/shared/types/error.type';

export type ContactsDetailsStateModel = {
    error?: ErrorType,
    formIsValid: boolean,
    contactsState: DetailState,
    contact?: Contact,
}

const defaults = {
    contactsState: DetailState.UPDATING,
    formIsValid: false,
};

function onChangeCurrentContact(change: Partial<Contact>) : StateOperator<ContactsDetailsStateModel> {
    return (state: Readonly<ContactsDetailsStateModel>) => ({
        ...state,
        contact: {
            ...(state.contact || {}) as Contact,
            ...change,
        },
    });
}

@State<ContactsDetailsStateModel>({
    name: 'contactsDetails',
    defaults,
})
@Injectable()
export class ContactsDetailsState {
    constructor(
        private contactsService: ContactsService,
    ) { }

    @Selector()
    static getContactDetailsState(state: ContactsDetailsStateModel): DetailState {
        return state.contactsState;
    }

    @Selector()
    static getContactId(state: ContactsDetailsStateModel): number | undefined {
        return state.contact?.id;
    }

    @Selector()
    static getContactDetailsError(state: ContactsDetailsStateModel): ErrorType | undefined {
        return state.error;
    }

    @Selector()
    static getContact(state: ContactsDetailsStateModel): Contact | undefined {
        return state.contact;
    }

    @Selector()
    static getContactName(state: ContactsDetailsStateModel): string | undefined {
        return state.contact ? `${state.contact.firstName} ${state.contact.lastName}` : '';
    }

    @Selector()
    static getFormIsValid(state: ContactsDetailsStateModel): boolean {
        return state.formIsValid;
    }

    @Action(ChangeContactStateAction)
    changeContactState({ patchState }: StateContext<ContactsDetailsStateModel>, action: ChangeContactStateAction) {
        patchState({
            contactsState: action.contactState,
        });
    }

    @Action(ChangeContactErrorAction)
    changeContactError({ patchState }: StateContext<ContactsDetailsStateModel>, action: ChangeContactErrorAction) {
        patchState({
            error: action.error,
        });
    }

    @Action(ChangeContactFieldAction)
    changeContactField({ setState }: StateContext<ContactsDetailsStateModel>, { key, value }: ChangeContactFieldAction) {
        setState(onChangeCurrentContact({

            [key]: value,
        }));
    }

    @Action(ChangeContactFieldsAction)
    changeContactFields({ setState }: StateContext<ContactsDetailsStateModel>, action: ChangeContactFieldsAction) {
        setState(onChangeCurrentContact({
            ...action.fieldsChanged,
        }));
    }

    @Action(CreateContactAction)
    createContact({ getState, patchState, dispatch }: StateContext<ContactsDetailsStateModel>) {
        const state = getState();

        if (!state.contact) {
            return EMPTY;
        }

        return this.contactsService.createContact(state.contact)
            .pipe(
                switchMap((createdContact) => {
                    const groups = state.contact?.groups ?? [];

                    const outputCreatedContact = {
                        ...state.contact,
                        ...createdContact,
                        groups,
                    };

                    patchState({
                        contact: outputCreatedContact,
                    });

                    return dispatch(new AddContactsToGroupsAction((state.contact?.groups ?? []).map((item) => item.id), [createdContact.id]))
                        .pipe(switchMap(() => dispatch(new CreateContactSuccessAction(outputCreatedContact))));
                }),
            );
    }

    @Action(UpdateContactAction)
    updateContact({ getState, patchState }: StateContext<ContactsDetailsStateModel>, action: UpdateContactAction) {
        const state = getState();

        if (!state.contact || !state.contact.id) {
            return EMPTY;
        }

        return this.contactsService.updateContact(state.contact.id, action.fieldsChanged)
            .pipe(
                tap((contact) => {
                    patchState({
                        contact: {
                            ...state.contact,
                            ...contact,
                        },
                    });
                }),
            );
    }

    @Action(UpdateFormIsValidAction)
    updateFormIsValid({ patchState }: StateContext<ContactsDetailsStateModel>, action: UpdateFormIsValidAction) {
        patchState({
            formIsValid: action.isValid,
        });
    }

    @Action(GetContactAction)
    getContact({ patchState }: StateContext<ContactsDetailsStateModel>, action: GetContactAction): Observable<unknown> {
        return this.contactsService.getContact(action.contactId).pipe(
            tap((contact) => {
                patchState({
                    contact: {
                        ...contact,
                    },
                });
            }),
        );
    }

    @Action(ResetContactAction)
    reset({ setState }: StateContext<ContactsDetailsStateModel>) {
        setState(defaults);
    }
}
